import { categoryService } from "@/services";

export const slideCategoriesCtrl = {
  getCategories: async () => {
    const { data } = await categoryService.getCategories();
    return data.data.results;
  },
  getCategory: async (categoryId) => {
    const { data } = await categoryService.getCategory(categoryId);
    const category = data.data;
    if (category.categories) {
      const categoryType =
        category.categories.length > 0
          ? category.categories.map(({ type, companion }) => ({
              type,
              id: companion,
            }))
          : [{ type: "sin acompañantes", id: "sin acompañantes" }];
      localStorage.setItem("categoryType", JSON.stringify(categoryType));
    }
    return category;
  },
};
