import { axiosInstance } from "@/core";
import { buildUrl, getConfigWithHeaderAuthorizationDefault } from "@/helpers";

const config = getConfigWithHeaderAuthorizationDefault;

export const reservationsService = {
  getAvailableShifts: async (payload) => {
    const url = buildUrl("reservations/getAvailableShifts");
    return axiosInstance.post(url, payload, config());
  },

  createBooking: async (payload) => {
    const url = buildUrl("reservations/insert");
    return axiosInstance.post(url, payload, config());
  },
};
