export const formatDateAndTime = (dateStr) => {
  const dateParsed = new Date(dateStr);
  let hours = dateParsed.getHours();
  let minutes = dateParsed.getMinutes();
  const formatMin = `${minutes < 10 ? "0" : ""}${minutes}`;
  const strDate = `${dateParsed.getDate()}/${
    dateParsed.getMonth() + 1
  }/${dateParsed.getFullYear()}`;
  const strTime = `${hours}:${formatMin}`;

  return `${strDate} ${strTime}`;
};
