export const esCheckPaymentLocale = {
  checkPayment: "Comprobar Pago",
  cancelPayment: "Su pago ha sido cancelado",
  confirmPayment: "Su pago ha sido confirmado",
  orderAccepted: "Pedido aceptado",
  orderAcceptedDescription: "Su pedido ha sido aceptado por el restaurante",
  orderRejected: "Pedido rechazado",
  orderRejectedDescription:
    "El pedido ha sido rechazado por su banco. Por favor contacte con el banco para más información o pruebe a realizar un nuevo pedido",
  orderRejectedByRestaurantDescription:
    "Su pedido ha sido rechazado por el restaurante. Para cualquier aclaración contacte con el restaurante. Disculpe las molestias",
  orderSent: "Pedido enviado",
  pendingPayment: "Pago pendiente",
  waitingConfirmPayment: "Esperando confirmación de pago",
  waitingConfirmRestaurant: "Esperando confirmación del restaurante",
};
