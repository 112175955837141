export const enCheckPaymentLocale = {
  checkPayment: "Check Payment",
  cancelPayment: "Your payment has been cancelled",
  confirmPayment: "Your payment has been confirmed",
  orderAccepted: "Order accepted",
  orderAcceptedDescription: "Your order has been accepted by the restaurant",
  orderRejected: "Order rejected",
  orderRejectedDescription:
    "The order has been rejected by your bank. Please contact your bank for more information or try placing a new order",
  orderRejectedByRestaurantDescription:
    "Your order has been rejected by the restaurant. For any clarification please contact the restaurant. Sorry for the inconvenience",
  orderSent: "Order sent",
  pendingPayment: "Pending payment",
  waitingConfirmPayment: "Waiting for payment confirmation",
  waitingConfirmRestaurant: "Waiting confirmation from the restaurant",
};
