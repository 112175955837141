export const adapterToSettingsDigitalLetter = ({
  logo,
  header_image,
  title_en,
  title_es,
  subtitle_en,
  subtitle_es,
  description_en,
  description_es,
  principal_color,
  page_background_color,
  header_background_color,
  minimal_order_domicile,
}) => ({
  images: { logo, header: header_image },
  texts: {
    title: { en: title_en, es: title_es },
    subtitle: { en: subtitle_en, es: subtitle_es },
    description: { en: description_en, es: description_es },
  },
  styles: {
    primaryColor: principal_color,
    bgPageColor: page_background_color,
    bgHeaderColor: header_background_color,
  },
  minimalOrderDomicile: minimal_order_domicile,
});
