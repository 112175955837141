import { compareProducts, updateTotalPriceProduct } from "@/helpers";

const locallyStoredCart = localStorage.getItem("cart");

const state = () => ({
  details: locallyStoredCart ? JSON.parse(locallyStoredCart).state.details : "",
  products: locallyStoredCart
    ? JSON.parse(locallyStoredCart).state.products
    : [],
});

const mutations = {
  PUSH_PRODUCT(state, product) {
    state.products = [...state.products, product];
  },

  NEW_PRODUCT_QTY(state, { product, addingProductQuantity }) {
    state.products = state.products.map((prod) => {
      if (compareProducts(prod, product)) {
        prod.qty += addingProductQuantity;
        updateTotalPriceProduct(prod);
      }
      return prod;
    });
  },

  INCREASE_PRODUCT_QTY(state, product) {
    state.products = state.products.map((prod) => {
      if (compareProducts(prod, product)) {
        prod.qty++;
        updateTotalPriceProduct(prod);
      }
      return prod;
    });
  },

  DECREASE_PRODUCT_QTY(state, product) {
    state.products = state.products.map((prod) => {
      if (compareProducts(prod, product)) {
        prod.qty--;
        updateTotalPriceProduct(prod);
      }
      return prod;
    });
  },

  REMOVE_PRODUCT(state, product) {
    state.products = state.products.filter(
      (prod) => !compareProducts(prod, product)
    );
  },

  CLEAR_CART(state) {
    state.details = "";
    state.products = [];
  },

  SET_DETAILS({ state }, payload) {
    state.details = payload;
  },
};

const getters = {
  totalQty: (state, getters) => {
    let products = getters.depuredProducts;
    return products.reduce((previousValue, { qty }) => {
      return previousValue + qty;
    }, 0);
  },
  totalPrice: (state, getters) => {
    const products = getters.depuredProducts;
    return products.reduce((previousValue, { price, qty, items }) => {
      const itemsTotalPrice =
        items.reduce(
          (previousValue, item) => previousValue + item.price * item.qty,
          0
        ) * qty;
      return previousValue + price * qty + itemsTotalPrice;
    }, 0);
  },
  depuredProducts: (state) => {
    const products = state.products;
    return products.map(({ id, name, price, qty, items, details }) => {
      return {
        id,
        name,
        details,
        price: Number(price),
        qty: Number(qty),
        is_offer: 0,
        items: items || [],
      };
    });
  },
  findProduct: (state) => (product) => {
    if (state.products.length === 0) return;
    return state.products.find((prod) => compareProducts(prod, product));
  },
};

const actions = {
  newProductQty(context, { product, addingProductQuantity }) {
    const isAlreadyContained =
      context.state.products[0] && context.getters.findProduct(product);
    if (isAlreadyContained) {
      context.commit("NEW_PRODUCT_QTY", {
        product,
        addingProductQuantity,
      });
    } else {
      product.qty = addingProductQuantity;
      context.commit("PUSH_PRODUCT", product);
    }
    context.dispatch("setCartInLocalstorage");
  },
  increaseProductQty(context, product) {
    const isAlreadyContained =
      context.state.products[0] && getters.findProduct(product);

    if (isAlreadyContained) {
      context.commit("INCREASE_PRODUCT_QTY", product);
    } else {
      product.qty = 1;
      context.commit("PUSH_PRODUCT", product);
    }
    context.dispatch("setCartInLocalstorage");
  },
  decreaseProductQty(context, product) {
    context.commit("DECREASE_PRODUCT_QTY", product);
    context.dispatch("setCartInLocalstorage");
  },
  removeProduct(context, product) {
    context.commit("REMOVE_PRODUCT", product);
    context.dispatch("setCartInLocalstorage");
  },
  clearCart(context) {
    context.commit("CLEAR_CART");
    context.dispatch("clearCartInLocalstorage");
  },
  setCartInLocalstorage({ state }) {
    window.localStorage.setItem(
      "cart",
      JSON.stringify({
        state,
      })
    );
  },
  clearCartInLocalstorage() {
    window.localStorage.removeItem("cart");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
