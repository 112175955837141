import { axiosInstance } from "@/core/axios";
import { addQueryParamsBase64ToUrl, buildUrl } from "@/helpers";

export const productsService = {
  getProductsByCategory: async (categoryId) => {
    const url = buildUrl(`products`);
    const paramsToConvertBase64 = {
      filters: { is_offer: false, category: categoryId },
      limit: 1000,
      offset: 0,
      order: "asc",
    };
    const urlWithQuery = addQueryParamsBase64ToUrl(url, paramsToConvertBase64);
    return await axiosInstance.get(urlWithQuery);
  },
};
