import { axiosInstance } from "@/core/axios";
import { addQueryParamsBase64ToUrl, buildUrl } from "@/helpers";

export const categoryService = {
  getCategories: async () => {
    const url = buildUrl(`categories`);
    const paramsToConvertBase64 = {
      excludes: { is_subcategory: false },
      filters: { is_active: true, customer_active: true },
      extras: { only_main: true },
      offset: 0,
      order: "asc",
      sort: ["order", "name"],
    };
    const urlWithQuery = addQueryParamsBase64ToUrl(url, paramsToConvertBase64);
    return await axiosInstance.get(urlWithQuery);
  },

  getCategory: async (categoryId) => {
    return await axiosInstance.get(buildUrl(`categories/${categoryId}`));
  },
};
