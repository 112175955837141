import { i18n } from "@/plugins/i18n";

const passwordErrors = {
  WEAK_PASSWORD: () => i18n.t("errorWeakPassword"),
};

const emailsErrors = {
  EMAIL: () => i18n.t("errorEmail"),
};

const httpErrors = {
  "401_UNAUTHORIZED": () => i18n.t("error401Unauthorized"),
};
export const errorMessages = {
  ...passwordErrors,
  ...emailsErrors,
  ...httpErrors,
  REQUIRED: () => i18n.t("errorRequired"),
  NOT_NUMBER: () => i18n.t("errorNotNumber"),
  NOT_EMOTICONS: () => i18n.t("errorNotEmoticons"),
  MIN_LENGTH: (min) => i18n.t("errorMinLength", { min }),
  MAX_LENGTH: (max) => i18n.t("errorMaxLength", { max }),
};
