import { axiosInstance } from "@/core";
import {
  buildUrl,
  getConfigWithHeaderAuthorizationDefault,
  addAccessTokenToHeaders,
} from "@/helpers";

import { orderTypeName } from "@/store/modules/orderType/constNames";

const configForOrderLocal = getConfigWithHeaderAuthorizationDefault;
const config = addAccessTokenToHeaders;

export const cartService = {
  sendOrder: async (order) => {
    const configAxios =
      order.type === orderTypeName.LOCAL.toLowerCase()
        ? configForOrderLocal
        : config;
    return await axiosInstance.post(buildUrl(`requests`), order, configAxios());
  },
};
